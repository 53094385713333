import "../../Assets/style/AllShipments.scss";
import { Fragment, useEffect, useState } from "react";
import { Top, Table } from "./components";

import axios from "axios";
import { generateTransactionID } from "../../Library/Utilities";
import { getShipmentHistory } from "../../Library/URLs";
import { AuthActions } from "../../store/AuthSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import WalletEmpty from "../Wallet/components/WalletEmpty";
import { Backdrop, Box, Fade, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "react-moment";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    outline: "none",
    outerWidth: "0px",
    borderRadius: "5px",
    py: 4,
    px: 2,
};

const EllaShipments = () => {
    const dispatch = useDispatch();
    const { User, ShipmentHistory } = useSelector((state) => state.Auth);

    const [filterType, setFilterType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedShipment, setSelectedShipment] = useState(null);

    const render = (res) => {
        switch (res) {
            case "Pending Pickup":
                return "Pending";
                break;
            case "Cancelled":
                return "Cancel";
                break;
            case "Rejected":
                return "Rejected";
                break;
            case "Delivered":
                return "Delivered";
                break;
            case "Pending Approval":
                return "Pending Approval";
                break;

            default:
                return "Success";
                break;
        }
    };

    const renderColor = (res) => {
        switch (res) {
            case "Pending Pickup":
                return "pending";
                break;
            case "Cancelled":
                return "cancel";
                break;
            case "Rejected":
                return "cancel";
                break;
            case "Delivered":
                return "success";
                break;
            case "Pending Approval":
                return "pending";
                break;

            default:
                return "success";
                break;
        }
    };

    useEffect(() => {
        const GetShipHistory = async () => {
            const Data = {
                user_id: User.userID,
                user_ses_id: User.sessionID,
                company_id: User.companyID,
                transaction_id: generateTransactionID(15),
                limit: "10",
                offset: "0",
            };
            await axios.post(getShipmentHistory, Data).then((response) => {
                console.log("shipment history res", response.data);
                if (response.data.success === false) {
                    toast.error(response.data.message);
                }
                if (response.data.success === true) {
                    const res = response.data.data;
                    dispatch(AuthActions.GetShipmentHistory(res));
                }
            });
        };
        GetShipHistory();
    }, []);

    const handleClose = () => {
        setSelectedShipment(null);
    };

    return (
        <Fragment>
            <div className="Breadcrum">
                <h5>All Shipments</h5>
            </div>
            <Top
                filterType={filterType}
                setFilterType={setFilterType}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            {ShipmentHistory === "" && (
                <span className="spinner-border spinner-grow-sm" />
            )}
            {ShipmentHistory.length > 0 ? (
                <Table
                    startDate={startDate}
                    endDate={endDate}
                    filterType={filterType}
                    setSelectedShipment={setSelectedShipment}
                />
            ) : (
                <WalletEmpty />
            )}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={selectedShipment !== null}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={selectedShipment !== null}>
                    <Box sx={style} className="Rate_Modal">
                        <div className="Top_section">
                            <h4>View Shipment</h4>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="ShipmentInfo">
                            <div className="ShipmentSection SectionDivide">
                                <p className="ShipmentLabel">SENDER DETAILS</p>
                                <p>{selectedShipment?.sender_name} </p>
                                <p>{selectedShipment?.sender_phone_no} </p>
                                <p>{`${selectedShipment?.sender_address_1}`} {selectedShipment?.sender_address_2 && `, ${selectedShipment?.sender_address_2}`} </p>
                                <p>
                                    {selectedShipment?.sender_state},{" "}
                                    {selectedShipment?.sender_city}{" "}
                                </p>
                                <p>{selectedShipment?.sender_country} </p>
                                <p>{selectedShipment?.sender_postcode} </p>
                            </div>
                            <div className="ShipmentSection SectionDivide">
                                <p className="ShipmentLabel">RECEIVER DETAILS</p>
                                <p>{selectedShipment?.receiver_name} </p>
                                <p>{selectedShipment?.receiver_phone_no} </p>
                                <p>{`${selectedShipment?.receiver_address_1}`} {selectedShipment?.receiver_address_2 && `, ${selectedShipment?.receiver_address_2}`} </p>
                                <p>
                                    {selectedShipment?.receiver_state},{" "}
                                    {selectedShipment?.receiver_city}{" "}
                                </p>
                                <p>{selectedShipment?.receiver_country} </p>
                                <p>{selectedShipment?.receiver_postcode} </p>
                            </div>
                            <div className="ShipmentSection pe-3">
                                <p className="ShipmentLabel mb-2">SHIPMENT DETAILS</p>
                                <div className="ShipmentInfo">
                                    <div>
                                        <p className="InfoLabel">Description</p>
                                        <p>{selectedShipment?.item_name} </p>
                                    </div>
                                    <div className="ShipRight">
                                        <p className="InfoLabel">Shipment No.</p>
                                        <p>{selectedShipment?.shipment_no} </p>
                                    </div>
                                    <div>
                                        <p className="InfoLabel">Payment Method</p>
                                        <p>{selectedShipment?.payment_method} </p>
                                    </div>
                                    <div className="ShipRight">
                                        <p className="InfoLabel">Amount</p>
                                        <p>{selectedShipment?.total_amount} </p>
                                    </div>
                                    <div>
                                        <p className="InfoLabel">Booking Date</p>
                                        <p>
                                            <Moment format="MMM DD YYYY, h:mm a">
                                                {selectedShipment?.date_time}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div className="">
                                        <p className="InfoLabel text-end">Status</p>
                                        <div className="d-flex justify-content-end">
                                            <div
                                                className={`BtnShip ${renderColor(
                                                    selectedShipment?.status
                                                )}`}
                                            >
                                                <span style={{ fontSize: 10 }}>
                                                    {render(selectedShipment?.status)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="BtnContain">
                                    <div className="w-100">
                                        <a
                                            href={selectedShipment?.invoice_url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="w-100"
                                        >
                                            <button className="FormBtn border-0 my-0 w-100">
                                                View Invoice
                                            </button>
                                        </a>
                                    </div>
                                    <div className=" w-100">
                                        {/* <a
                                            href={selectedShipment?.waybill_document_url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="w-100"
                                        >
                                            <button className="FormBtn border-0 my-0 w-100">
                                                View Waybill
                                            </button>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </Fragment>
    );
};

export default EllaShipments;
