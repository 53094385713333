import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  outline: 'none',
  outerWidth: '0px',
  borderRadius: '5px',
  py: 4,
  px: 2,
}

const RatesModal = ({ carriers, open, handleClose }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="Rate_Modal" >
          <div className="Top_section" >
            <h4>Shipping rate options</h4>
            <IconButton onClick={handleClose} >
              <CloseIcon />
            </IconButton>
          </div>
          {carriers?.length > 0 ?carriers?.map((carrier, id) => (
            <div key={id} className="Carrier_item" >
              <div className="Carrier_details" >
                <img className="Carrier_img" src={carrier?.carrier_logo} alt={carrier?.carrier_name} />
                <div>
                  <p className="Carrier_name" >{carrier?.carrier_name}</p>
                  <p className="Carrier_delivery" >{carrier?.delivery_time}</p>
                </div>
              </div>
              <div>
                <p className="Carrier_amount" >
                  {carrier?.selling_price !== "Coming Soon" ? carrier?.currency === "NGN" ? (
                    <span>&#8358;</span>
                  ) : carrier?.currency === "GBP" ? (
                    <span>&#163;</span>
                  ) : carrier?.currency === "USD" ? (
                    <span>&#36;</span>
                  ) :
                  carrier?.currency === "EUR" ? (
                    <span>&#8364;</span>
                  ) : (
                    <span>&#8358;</span>
                  ) : null}{" "}
                  {parseFloat(`${carrier?.selling_price}`).toLocaleString("en-US")}
                </p>
              </div>
            </div>
          )) : <p>No Carrier is available for this shipment</p>}
        </Box>
      </Fade>
    </Modal>
  );
};

export default RatesModal;
