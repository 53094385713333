import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { generateTransactionID } from "../../../Library/Utilities";
import {
  BookInternationalShipment,
  ClearRecord,
} from "../../../store/InternationalAction";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import NestedBtn from "../../../components/NestedBtn";
import axios from "axios";
import { InternationalActions } from "../../../store/InternationalSlice";
import { createShipmentX, createIntentUrl } from "../../../Library/URLs";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { BsCheck } from "react-icons/bs";
import StripeModal from "../../../components/stripe/StripeModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  p: 4,
};

const ConfrimDetail = ({ setTab, PaymentStatus }) => {
  const [Pickup, setPickup] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [shipInfo, setShipInfo] = useState({});

  const [clientSecret, setClientSecret] = useState(null);

  const handleClose = () => {
    setClientSecret(null);
  };

  const payWithStripe = async () => {
    setIsLoading(true);

    const raw = {
      user_id: User.userID,
      user_type: "Regular",
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      amount: parseInt(
        SelectedCarrier?.selling_price?.replaceAll(",", "")?.replaceAll(".", "")
      ),
      currency: SelectedCarrier?.currency
        ? SelectedCarrier?.currency?.toLowerCase()
        : "ngn",
    };
    await axios.post(createIntentUrl, raw).then((res) => {
      // console.log("inter res", res.data);
      setIsLoading(false);
      if (res.data?.success) {
        setClientSecret(res?.data?.data);
      } else if (res.data?.success === false) {
        toast.error(res.data?.message);
      }
    });
  };

  const [PickLocation, setLocation] = useState("");

  const dispatch = useDispatch();

  const { User, WalletDetails } = useSelector((state) => state.Auth);
  const [isLoading, setIsLoading] = useState(false);

  const {
    Overall,
    // isLoading,
    isBook,
    Sender,
    Receiver,
    TotalValue,
    PaymentOption,
    Package,
    PickUp_Location,
    RequestPack,
    PriceDetails,
    TotalWeight,
    SenderCountry,
    ReceiverCountry,
    SelectedCarrier,
    packaging_info,
    SenderState,
    ReceiverState,
    ReceiverCity,
    SenderCity,
    insurance,
  } = useSelector((state) => state.International);

  const handleLocation = (e) => {
    setLocation(e.target.value);
    dispatch(InternationalActions.GetPickUp(e.target.value));
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: User.email,
    amount: parseInt(
      `${SelectedCarrier?.selling_price}`
        ?.replaceAll(",", "")
        ?.replaceAll(".", "")
    ), //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
  };

  const onSuccess = (reference) => {
    toast.success("Payment successful!");
    BookShipment(reference?.reference);
    console.log("paystack success", reference);
  };

  const onClose = () => {
    toast.error("Payment fail");
    console.log("paystack closed");
  };

  const PaystackHookExample = () => {
    // console.log("pays",parseInt(SelectedCarrier?.selling_price?.replaceAll(",", "")?.replaceAll(".", "")));
    const initializePayment = usePaystackPayment(config);
    return (
      <button
        onClick={() => {
          initializePayment(onSuccess, onClose);
        }}
      >
        BOOK SHIPMENT
      </button>
    );
  };

  const HomeHandle = () => {
    dispatch(ClearRecord());
    setTab(0);
  };

  const Previous = () => {
    setTab(4);
  };

  // This function gets the description of all items in the package
  // and concatenate into a string
  function getDescriptionString(array) {
    return array.map((obj) => obj.description).join(", ");
  }

  const BookShipment = async (reference) => {
    setIsLoading(true);
    const data = {
      booking: {
        rate_id: SelectedCarrier?.rate_id,
        carrier_id: SelectedCarrier?.carrier_id,
        processing_station_id: Sender?.processing_unit,
        schedule_date: "Now",
        currency:
          SenderCountry !== "Nigeria" || ReceiverCountry !== "Nigeria"
            ? "USD"
            : "NGN",
        payment_method: PaymentOption,
        insurance: {
          request_insurance: "No",
          insurance_type: "Standard",
          insurance_fee: 0,
        },
        pickup: {
          request_pickup: "No",
          pickup_type_id: "Standard",
          pickup_fee: 0,
        },
        services: [],
        fees: {
          standard_fee: SelectedCarrier?.selling_price, //amount for just shipping the item
          services_fee: 0, //total sum of services
          extras_fee: 0, //total sum of insurance and pickup
          shipping_fee: SelectedCarrier?.selling_price, //total of standard + services + extras
        },
      },
      sender: {
        first_name: Sender.first_name,
        last_name: Sender.last_name,
        address_1: Sender.street,
        address_2: "",
        city: SenderCity,
        state: SenderState,
        country: SenderCountry,
        postcode: Sender.post_code,
        email: User.email,
        phone_no: Sender.phone_number,
      },
      receiver: {
        first_name: Receiver.first_name,
        last_name: Receiver.last_name,
        address_1: Receiver.street,
        address_2: "",
        city: ReceiverCity,
        state: ReceiverState,
        country: ReceiverCountry,
        postcode: Receiver.post_code,
        email: User.email,
        phone_no: Receiver.phone_number,
      },
      package: {
        package_type: "Parcel",
        package_image_url: "",
        packages: Package,
      },
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      shipment_type:
        SenderCountry === ReceiverCountry
          ? SenderCity === ReceiverCity
            ? "local"
            : "Interstate"
          : SenderCountry !== "Nigeria" && ReceiverCountry === "Nigeria"
          ? "Import"
          : "International",
      flutterwave_amount: SelectedCarrier?.selling_price,
      transaction_reference_no: reference,
    };
    // const data = {
    //   packages: Package,
    //   user_id: User.userID,
    //   user_ses_id: User.sessionID,
    //   company_id: User.companyID,
    //   transaction_id: generateTransactionID(15),
    //   // processor: SelectedCarrier?.carrier_name,
    //   processor: SelectedCarrier?.carrier_name,
    //   payment_method: PaymentOption,
    //   sender_first_name: Sender.first_name,
    //   sender_last_name: Sender.last_name,
    //   sender_phone_no: Sender.phone_number,
    //   sender_postcode: Sender.post_code,
    //   sender_company_name: "Ella Logistics",
    //   pick_up_location: Sender.street,
    //   sender_address_1: Sender.street,
    //   sender_city: SenderCity,
    //   sender_state: SenderState,
    //   sender_country: SenderCountry,
    //   sender_email: User.email,
    //   drop_off_location: Receiver.street,
    //   recipient_address_1: Receiver.street,
    //   recipient_first_name: Receiver.first_name,
    //   recipient_last_name: Receiver.last_name,
    //   recipient_phone_no: Receiver.phone_number,
    //   recipient_email: User.email,
    //   description: getDescriptionString(allPackageItems),
    //   request_type:
    //     SenderCountry === ReceiverCountry
    //       ? SenderCity === ReceiverCity
    //         ? "local"
    //         : "Interstate"
    //       : SenderCountry !== "Nigeria" && ReceiverCountry === "Nigeria"
    //       ? "Import"
    //       : "International",
    //   recipient_postcode: Receiver.post_code,
    //   recipient_city: ReceiverCity,
    //   recipient_state: ReceiverState,
    //   recipient_country: ReceiverCountry,
    //   weight: packageWeight,
    //   amount: SelectedCarrier?.selling_price?.replaceAll(",", ""),
    //   flutterwave_amount: PriceDetails.flutterwave_amount,
    //   item_value: packageValue,
    //   item_value_currency: allPackageItems[0]?.currency,
    //   processing_unit: Sender?.processing_unit,
    //   length: packaging_info?.length,
    //   breadth: packaging_info?.width,
    //   height: packaging_info?.height,
    //   insurance: insurance,
    //   origin_of_content: null,
    //   request_pickup: "No",
    //   transaction_reference_no: reference ? reference : "",
    //   carrier_id: "1",
    //   pickup_address_id: "",
    //   dropoff_address_id: "",
    //   parcel_id: "",
    //   carrier_cost_price: SelectedCarrier?.selling_price?.replaceAll(",", ""),
    //   currency: SelectedCarrier?.currency ? SelectedCarrier?.currency : "NGN",
    //   request_pickup_carrier_id: "",
    //   request_pickup_address_id: "",
    //   request_dropoff_address_id: "",
    //   booking_type: "Now",
    //   schedule_date: "",
    // };
    await axios.post(createShipmentX, data).then((res) => {
      // console.log("inter res", res.data);
      setIsLoading(false);
      if (res.data?.success) {
        // toast.success("Shipment as been successfully book");
        setShowModal(true);
        setShipInfo(res.data);
      } else if (res.data?.success === false) {
        dispatch(InternationalActions.ShipmentStatus(false));
        toast.error(res?.data.message);
      }
    });
    // dispatch(BookInternationalShipment(data));
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  let packageValue = Package?.reduce((accumulator, currentObject) => {
    return accumulator + parseFloat(currentObject?.package_value);
  }, 0);

  let packageWeight = Package?.reduce((accumulator, currentObject) => {
    return accumulator + parseFloat(currentObject?.package_weight);
  }, 0);

  let allPackageItems = [];

  // Outer loop to iterate through the varObj array
  for (let i = 0; i < Package.length; i++) {
    // Inner loop to iterate through the sports array for each object
    for (let j = 0; j < Package[i].package_items.length; j++) {
      // Push each sport object into the allSports array
      allPackageItems.push(Package[i].package_items[j]);
    }
  }

  // useEffect(() => {
  //   const Fetch = async () => {
  //     const data = {
  //       user_id: User.userID,
  //       user_ses_id: User.sessionID,
  //       company_id: User.companyID,
  //       transaction_id: generateTransactionID(15),
  //     };
  //     await axios
  //       .post(
  //         "https://user.parcelflow.io/engine/v1/actions/get-pickup-locations",
  //         data
  //       )
  //       .then((res) => {
  //         if (res.data.success === true) {
  //           setPickup(res.data.data);
  //           console.log("pick", Pickup);
  //         }
  //         if (res.data.success === false) {
  //           setPickup("");
  //           toast.error(res.data.message);
  //         }
  //       });
  //   };
  //   Fetch();
  // }, [Pickup]);

  const closeModal = () => {
    dispatch(InternationalActions.ShipmentStatus(true));
    dispatch(InternationalActions.Reset());
    setTab(0);
    window.scrollTo(0, 0);
    setShowModal(false);
  };

  return (
    <>
      <StripeModal
        clientSecret={clientSecret}
        handleClose={handleClose}
        handleSuccess={BookShipment}
      />
      {isBook === true ? (
        <div className="Successfull">
          <svg
            width="90"
            height="89"
            viewBox="0 0 90 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M45.0961 88.91C69.6104 88.91 89.4832 69.0372 89.4832 44.5229C89.4832 20.0085 69.6104 0.135742 45.0961 0.135742C20.5818 0.135742 0.708984 20.0085 0.708984 44.5229C0.708984 69.0372 20.5818 88.91 45.0961 88.91ZM65.6646 37.3494C67.8314 35.1826 67.8314 31.6696 65.6646 29.5028C63.4978 27.336 59.9847 27.336 57.818 29.5028L39.5477 47.773L32.3742 40.5995C30.2075 38.4328 26.6944 38.4328 24.5276 40.5995C22.3608 42.7663 22.3608 46.2794 24.5276 48.4462L35.6244 59.5429C37.7912 61.7097 41.3042 61.7097 43.471 59.5429L65.6646 37.3494Z"
              fill="#2DD4BF"
            />
          </svg>
          <h4>Shipment as been book Successfully</h4>
          <p>Everything is Fine, you can go ahead to track your delivery</p>
          <div onClick={HomeHandle} className="Btn">
            Back
          </div>
        </div>
      ) : (
        <div data-aos="fade-zoom-in" className="Confirm">
          <h3>Confirm Details</h3>
          <div className="Box">
            <p>SUMMARY</p>
          </div>
          {/* <div className="Box">
            <p>SELECT PROCESSING AREA</p>
            <div className="Right">
              <select
                value={PickLocation}
                onChange={(e) => handleLocation(e)}
                name=""
                id=""
              >
                <option value="">Choose Processing Area</option>
                {Pickup !== "" &&
                  Pickup.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.city_name}
                    </option>
                  ))}
              </select>
            </div>
          </div> */}
          <div className="Box">
            <p>SENDER DETAILS</p>
            <div className="Right">
              <h4>
                {Sender.first_name} {Sender.last_name}
              </h4>
              <h6>{Sender.street}</h6>
            </div>
          </div>
          <div className="Box">
            <p>RECEIVER DETAILS</p>
            <div className="Right">
              <h4>
                {Receiver.first_name} {Receiver.last_name}
              </h4>
              <h6>{Receiver.street}</h6>
            </div>
          </div>
          <div className="Box">
            <p>DECLARED WEIGHT</p>
            <div className="Right">
              <h4>{packageWeight?.toFixed(2)}KG</h4>
              {/* <h6>
                {packaging_info?.length}CM X {packaging_info?.width}CM X{" "}
                {packaging_info?.height}CM
              </h6> */}
            </div>
          </div>
          <div className="Box">
            <p>PACKAGE</p>
            <div className="Right">
              {allPackageItems.map((item, index) => (
                <h6 key={index}>{item.description} </h6>
              ))}
            </div>
          </div>
          <div className="Box">
            <p>PACKAGE VALUE</p>
            <div className="Right">
              <h4>
                {allPackageItems[0]?.currency === "USD" ? "$" : "₦"}
                {parseFloat(packageValue).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </h4>
            </div>
          </div>
          <div className="Box">
            <p>INSURANCE</p>
            <div className="Right">
              <h4>{insurance}</h4>
            </div>
          </div>
          <div className="Box">
            <p>PAYMENT METHOD</p>
            <div className="Right">
              <h4>{PaymentOption}</h4>
            </div>
          </div>
          <div className="Bottom_Box">
            <h3>
              {SelectedCarrier?.currency ? SelectedCarrier?.currency : "NGN"}{" "}
              {parseInt(
                `${SelectedCarrier?.selling_price}`?.replaceAll(",", "")
              )}
            </h3>
            <h5>Inc. VAT</h5>
          </div>
          {PaymentOption === "Wallet" ? (
            <button onClick={() => BookShipment()}>
              {isLoading === true ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "BOOK SHIPMENT"
              )}
            </button>
          ) : PaymentOption === "Paystack" ? (
            <PaystackHookExample />
          ) : (
            <button onClick={payWithStripe}>
              {isLoading === true ? (
                <span className="spinner-border spinner-grow-sm" />
              ) : (
                "BOOK SHIPMENT"
              )}
            </button>
          )}

          <small>
            By continuing, I represent that the declaration above is a proper
            and accurate description of the contents of my package.
          </small>
          <NestedBtn handle={Previous} status={PaymentStatus} name="previous" />
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <Box sx={style}>
            <div className="Package_Modal_Wrapper">
              <div className="Box">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <div className="Check_Circle">
                    <BsCheck />
                  </div>
                </div>
                <p className="BookedTitle">
                  Shipment Booked - {shipInfo?.shipment_number}
                </p>
                <p className="BookedText">
                  Thank you for booking with Ella Logistics
                </p>
                <div className="d-flex justify-content-center mt-2 px-3">
                  <button onClick={closeModal} className="BookedBtn">
                    Continue
                  </button>
                </div>
                {/* <div className="d-flex justify-content-center px-3">
                  <a
                    className="w-100"
                    href={shipInfo?.invoice_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="BookedBtn">View Invoice</button>
                  </a>
                </div> */}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConfrimDetail;
