import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { InternationalActions } from "../../../store/InternationalSlice";
import NestedBtn from "../../../components/NestedBtn";
import {
  calculateShipmentX as calculateEllaShipment,
  uploadItemImgUrl,
} from "../../../Library/URLs";
import { generateTransactionID } from "../../../Library/Utilities";
import axios from "axios";
import { toast } from "react-toastify";
import Slider from "react-slick";
import classNames from "classnames";
import categories from "../../../Data/packageCategories.json";
import { FaPlusCircle, FaRegTrashAlt } from "react-icons/fa";
import { FiUploadCloud } from "react-icons/fi";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 23,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#E92D38",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
    backgroundColor: "#FFF",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#1e2345c4",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  outerWidth: "0px",
  borderRadius: "5px",
  p: 4,
  overflowY: "auto",
  maxHeight: "680px",
};

const arrayToObject = (arr) => {
  const result = {};
  arr.forEach((item, index) => {
    result[index] = item;
  });
  return result;
};

const roundNumber = (number) => {
  let roundedNumber = Math.round(number * 10) / 10;
  return roundedNumber;
};

const PackageDetail = ({ setTab, ReceiverStatus }) => {
  const {
    Package,
    TotalQuantity,
    TotalValue,
    Sender,
    Receiver,
    SenderCountry,
    SenderState,
    SenderCity,
    ReceiverCity,
    ReceiverCountry,
    ReceiverState,
    Overall: OverrallState,
    packaging_info,
    TotalWeight,
    insurance,
  } = useSelector((state) => state.International);
  const { User } = useSelector((state) => state.Auth);
  const transaction_id = generateTransactionID(15);

  const [categoryQuery, setCategoryQuery] = useState("");
  const [catError, setCatError] = useState(false);
  const [showCategoriesSuggestions, setShowCategoriesSuggestions] =
    useState(false);
  const [categorySuggestions, setCategorySuggestions] = useState([]);

  const [showAddCatSuggestions, setShowAddCatSuggestions] = useState(false);
  const [addCatQuery, setAddCatQuery] = useState("");
  const [addCatSuggestions, setAddCatSuggestions] = useState([]);
  const [addCat, setAddCat] = useState("");
  const [itemImg, setItemImg] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);

  const selectEditCategory = (value) => {
    setEditPackage({
      ...EditPackage,
      name: categories.find((val) => val?.label === value)?.value,
      hs_code: categories.find((val) => val?.label === value)?.hs_code,
    });
    setCategoryQuery(value);
    setShowCategoriesSuggestions(false);
  };

  const searchCategory = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setCategoryQuery(query);

    if (query === "") {
      setShowCategoriesSuggestions(false);
      return;
    }

    const filteredCountries = [];
    categories.map((cat) => {
      if (cat["label"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(cat["label"]);
      }
    });

    setCategorySuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowCategoriesSuggestions(true);
    } else {
      setShowCategoriesSuggestions(false);
    }
  };

  const selectAddCategory = (value) => {
    setAddCat(value);
    setAddCatQuery(value);
    setShowAddCatSuggestions(false);
  };

  const searchAddCategory = (query) => {
    // dispatch(InternationalActions.GetCost(false))
    setAddCatQuery(query);

    if (query === "") {
      setShowAddCatSuggestions(false);
      return;
    }

    const filteredCountries = [];
    categories.map((cat) => {
      if (cat["label"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(cat["label"]);
      }
    });

    setAddCatSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      setShowAddCatSuggestions(true);
    } else {
      setShowAddCatSuggestions(false);
    }
  };

  const calculateShipment = async (allPackageItems) => {
    setIsLoading(true);

    const raw = {
      user_id: User.userID,
      user_type: "Regular",
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: transaction_id,
      sender: {
        first_name: Sender.first_name,
        last_name: Sender.last_name,
        address_1: Sender.street,
        address_2: "",
        city: SenderCity,
        state: SenderState,
        country: SenderCountry,
        postcode: Sender.post_code,
        email: User.email,
        phone_no: Sender.phone_number
      },
      receiver: {
        first_name: Receiver.first_name,
        last_name: Receiver.last_name,
        address_1: Receiver.street,
        address_2: "",
        city: ReceiverCity,
        state: ReceiverState,
        country: ReceiverCountry,
        postcode: Receiver.post_code,
        email: User.email,
        phone_no: Receiver.phone_number
      },
      package: {
        package_type: "Parcel",
        package_image_url: "",
        packages: Package,
      },
      type:
        SenderCountry === "Nigeria" && ReceiverCountry === "Nigeria"
          ? SenderCity === ReceiverCity
            ? "local"
            : "Interstate"
          : SenderCountry !== "Nigeria" && ReceiverCountry === "Nigeria"
          ? "Import"
          : "International",
    };
    await axios.post(calculateEllaShipment, raw).then((res) => {
      console.log("inter res", res.data);
      setIsLoading(false);
      if (res.data?.success) {
        setTab(3);
        window.scrollTo(0, 0);
        dispatch(InternationalActions.GetSelectedCarrier(""));
        dispatch(InternationalActions.GetCost(false));
        dispatch(InternationalActions.GetCarrierInfo(res.data?.data?.carriers));
        dispatch(InternationalActions.GetPackageStatus(true));
      } else if (res.data?.success === false) {
        toast.error(res.data?.message);
      }
    });
  };

  const [selectedPackage, setSelectedPackage] = useState(0);
  // Link this ref to the tab slider
  const sliderTabRef = useRef();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setItemImg(null);
    setImgLoading(false);
  };
  const [isLoading, setIsLoading] = useState(false);

  const [isEmpty, setisEmpty] = useState(false);
  const [packageIsEmpty, setPackageIsEmpty] = useState(false);
  const [Overall, setOverall] = useState(OverrallState);
  const [packagingInfo, setPackagingInfo] = useState({
    length: packaging_info?.length ?? "",
    width: packaging_info?.width ?? "",
    height: packaging_info?.height ?? "",
  });
  const [OveralModal, setOveralModal] = useState(false);
  const handleOverallOpen = () => setOveralModal(true);

  const OveralSubmit = () => {
    if (Package[selectedPackage]?.description === "") {
      setisEmpty(true);
    }
    if (
      Package[selectedPackage]?.package_height === "" ||
      Package[selectedPackage]?.package_width === "" ||
      Package[selectedPackage]?.package_length === ""
    ) {
      setPackageIsEmpty(true);
    }
    if (
      Package[selectedPackage]?.description !== "" &&
      Package[selectedPackage]?.description.length > 3
    ) {
      // dispatch(InternationalActions.GetOverall(Overall));
      // dispatch(InternationalActions.GetPackagingInfo(packagingInfo));
      console.log(Overall);
      setisEmpty(false);
      setPackageIsEmpty(false);
      setOveralModal(false);
    }
  };

  const handleSummaryInput = (e) => {
    const packagesCopy = [...Package];

    packagesCopy[selectedPackage] = {
      ...packagesCopy[selectedPackage],
      [e.target.name]: e.target.value,
    };
    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  const [EditPackage, setEditPackage] = useState(null);
  const [Editopen, setEditopen] = useState(false);
  const handleEditOpen = (item) => {
    setCategoryQuery(categories.find((cat) => cat.value === item?.name)?.label);
    setEditPackage(item);
    setEditopen(true);
  };
  const handleEditClose = () => {
    setEditopen(false);
    setItemImg(null);
  };

  const SubmitUpdate = () => {
    editItem();
    setEditopen(false);
    setItemImg(null);
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isClear, setIsClear] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  // useEffect(() => {
  //   if (!isClear) {
  //     return;
  //   } else {
  //     reset();
  //   }
  // }, [isClear, reset]);

  const onSubmit = (data) => {
    if (data) {
      dispatch(
        InternationalActions.GetPackage({
          id: Package.length + 1,
          ...data,
        })
      );
      dispatch(InternationalActions.GetQtyVal());
      dispatch(InternationalActions.GetRequestPack());
      dispatch(InternationalActions.GetPackageStatus(true));
      setOpen(false);
      // setIsClear(true);
      reset();
      console.log(isClear);
    } else setIsClear(false);
  };

  const SubmitForm = () => {
    let allPackageItems = [];

    // Outer loop to iterate through the varObj array
    for (let i = 0; i < Package.length; i++) {
      // Inner loop to iterate through the sports array for each object
      for (let j = 0; j < Package[i].package_items.length; j++) {
        // Push each sport object into the allSports array
        allPackageItems.push(Package[i].package_items[j]);
      }
    }

    if (allPackageItems?.length < 1) {
      toast.error("Please add item(s) to this package.");
      return;
    }

    if (insurance === "Yes") {
      if (!allPackageItems.every((item) => item.image_name && item.image_url)) {
        toast.error("Please ensure you uploaded an image for every item.");
        return;
      }
    }

    let isEmpty = false;

    for (const pkg of Package) {
      if (!pkg.package_length || !pkg.package_width || !pkg.package_height) {
        isEmpty = true;
        break;
      }
    }

    if (isEmpty) {
      toast.error(
        "Please ensure to provide your package length, width and height by clicking change on the package description"
      );
      return;
    }
    if (Package.length < 1) {
      return;
    } else {
      calculateShipment(allPackageItems);
    }
  };

  const Previous = () => {
    setTab(1);
  };

  const addPackage = () => {
    const packagesCopy = [...Package];

    packagesCopy.push({
      package_items: [],
      package_weight: "0",
      package_length: "",
      package_width: "",
      package_height: "",
      package_value: "0",
      number_of_items: "0",
    });
    goToSlide(packagesCopy?.length - 1);

    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  const removePackage = (packageId) => {
    const packagesCopy = [...Package];

    packagesCopy?.splice(packageId, 1);
    goToSlide(selectedPackage - 1);
    dispatch(InternationalActions.UpdatePackage(packagesCopy));
  };

  // Funtion to update the current content
  const goToSlide = (index) => {
    if (sliderTabRef.current) {
      setSelectedPackage(index);
      sliderTabRef.current.slickGoTo(index);
    }
  };

  // Slider settings for package contents
  // After change gets the index of the current slide
  const packageSettings = {
    afterChange: (currentSlide) => {
      console.log("iiwiw", currentSlide);
      setSelectedPackage(parseInt(currentSlide));
    },
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: false,
    // centerPadding: "20px",
    arrows: false,
  };

  // Slider settings for contents tab
  const tabSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: false,
    centerMode: false,
  };

  const removeItem = (id, packageId) => {
    let packages = [...Package];
    let packageItems = [...Package[packageId]?.package_items];

    packageItems.splice(id, 1);

    let itemWeight =
      parseFloat(Package[packageId]?.package_weight) -
      parseFloat(Package[packageId]?.package_items[id]?.weight);

    let new_item_value =
      parseFloat(Package[packageId]?.package_value) -
      (parseFloat(Package[packageId]?.package_items[id]?.value) ?? 0) *
        parseInt(Package[packageId]?.package_items[id]?.quantity ?? 0);
    let new_quantity =
      parseFloat(Package[packageId]?.number_of_items) -
      parseFloat(Package[packageId]?.package_items[id]?.quantity);

    packages[packageId] = {
      ...packages[packageId],
      package_items: packageItems,
      package_weight: itemWeight,
      package_value: new_item_value,
      number_of_items: new_quantity,
    };

    dispatch(InternationalActions.UpdatePackage(packages));
    setEditopen(false);
  };

  const addPackageItem = (data) => {
    // console.log("osp", data);
    // let packageCount = (Object.keys(userForm.packages).length + 1).toString();
    if (addCat !== "") {
      const copyPackages = [...Package];
      if (Package[0]?.package_items?.length > 0) {
        let initialPackageCurrency = Package[0]?.package_items[0]?.currency;
        if (data?.currency !== initialPackageCurrency) {
          toast.error(
            "We noticed differences in your package currency. To ensure your shipment details is accurate, please ensure you maintain a single currency"
          );
        }
      }
      setCatError(false);
      console.log("ppalf", selectedPackage);
      const copyPackageItems = [...Package[selectedPackage]?.package_items];
      copyPackageItems.push({
        ...data,
        name: categories.find((cat) => cat.label === addCat)?.value,
        hs_code: categories.find((cat) => cat.label === addCat)?.hs_code,
      });
      if (insurance === "Yes") {
        if (itemImg) {
          copyPackageItems[copyPackageItems?.length - 1] = {
            ...copyPackageItems[copyPackageItems?.length - 1],
            image_name: itemImg?.name,
            image_url: itemImg?.url,
          };
        } else {
          toast.error("Please provide item image");
        }
      }
      console.log("pdf", copyPackageItems);
      copyPackages[selectedPackage] = {
        ...copyPackages[selectedPackage],
        package_items: copyPackageItems,
        package_weight: roundNumber(
          parseFloat(copyPackages[selectedPackage]?.package_weight) +
            parseFloat(data.weight)
        ),
        package_value:
          parseFloat(copyPackages[selectedPackage]?.package_value) +
          parseFloat(data.value) * parseInt(data.quantity),
        number_of_items:
          parseFloat(copyPackages[selectedPackage]?.number_of_items) +
          parseInt(data.quantity),
      };

      dispatch(InternationalActions.UpdatePackage(copyPackages));
      setOpen(false);
      setItemImg(null);
      // setIsClear(true);
      reset();
    } else {
      setCatError(true);
    }
  };

  const editItem = () => {
    // let packageCount = (Object.keys(userForm.packages).length + 1).toString();
    const copyPackages = [...Package];
    const copyPackageItems = [...Package[selectedPackage]?.package_items];

    if (Package[0]?.package_items?.length > 0) {
      let initialPackageCurrency = Package[0]?.package_items[0]?.currency;
      if (EditPackage?.currency !== initialPackageCurrency) {
        toast.error(
          "We noticed differences in your package currency. To ensure your shipment details is accurate, please ensure you maintain a single currency"
        );
      }
    }

    copyPackageItems[EditPackage.id] = {
      ...EditPackage,
    };
    if (insurance === "Yes") {
      if (itemImg) {
        copyPackageItems[EditPackage.id] = {
          ...EditPackage,
          image_name: itemImg?.name,
          image_url: itemImg?.url,
        };
      }
    }
    console.log("llaka", copyPackageItems);
    copyPackages[selectedPackage] = {
      ...copyPackages[selectedPackage],
      package_items: copyPackageItems,
      package_weight: roundNumber(
        parseFloat(copyPackages[selectedPackage]?.package_weight) -
          parseFloat(
            Package[selectedPackage]?.package_items[EditPackage.id]?.weight
          ) +
          parseFloat(EditPackage.weight)
      ),
      package_value:
        parseFloat(copyPackages[selectedPackage]?.package_value) -
        parseFloat(
          Package[selectedPackage]?.package_items[EditPackage.id]?.value
        ) *
          parseInt(
            Package[selectedPackage]?.package_items[EditPackage.id]?.quantity
          ) +
        parseFloat(EditPackage.value) * parseInt(EditPackage.quantity),
      number_of_items:
        parseInt(copyPackages[selectedPackage]?.number_of_items) -
        parseFloat(
          Package[selectedPackage]?.package_items[EditPackage.id]?.quantity
        ) +
        parseInt(EditPackage.quantity),
    };

    dispatch(InternationalActions.UpdatePackage(copyPackages));
  };

  const handleInsurance = () => {
    if (insurance === "Yes") {
      dispatch(InternationalActions.UpdateInsurance("No"));
    } else {
      dispatch(InternationalActions.UpdateInsurance("Yes"));
    }
  };

  const handleInsuranceFile = (e) => {
    const { files } = e.target;

    const file = files[0];

    uploadItemImg(file?.name, file);
  };

  const uploadItemImg = async (filename, file) => {
    setImgLoading(true);
    const formData = new FormData();
    formData.append("document", file);

    await axios.post(uploadItemImgUrl, formData).then((res) => {
      setImgLoading(false);
      setItemImg({
        name: filename,
        url: res?.data,
      });
    });
  };

  return (
    <>
      <div data-aos="fade-zoom-in" className="Package_Detail shipment-slick">
        <h3>Enter Package Details</h3>
        <div className="Top">
          <div className="d-flex w-100">
            <div className="d-block w-100">
              <Slider ref={sliderTabRef} {...tabSettings}>
                {Package.map((packageItem, id) => (
                  <button
                    type="button"
                    className={`${selectedPackage !== id && "btnTab"}`}
                    onClick={() => goToSlide(id)}
                  >
                    Package {id + 1}
                  </button>
                ))}
              </Slider>
            </div>
            <button
              onClick={addPackage}
              className="btnTab d-none d-md-flex gap-2 justify-content-center align-items-center"
              style={{ minWidth: "20%" }}
            >
              Add Package
              <FaPlusCircle style={{ color: "#E92D38" }} />
            </button>
          </div>
          <div className="d-flex d-md-none gap-5">
            <button
              onClick={addPackage}
              className="btnTab d-flex d-md-none gap-2 justify-content-center align-items-center"
            >
              Add Package
              <FaPlusCircle style={{ color: "#E92D38" }} />
            </button>
            <button
              className="btnTab"
              disabled={Package?.length < 2}
              onClick={() => removePackage(selectedPackage)}
            >
              Remove Package
            </button>
          </div>
          <button
            className=" d-none d-md-flex gap-2 justify-content-center align-items-center"
            disabled={Package?.length < 2}
            style={{
              border: "none",
              color: "#6D7D93",
              backgroundColor: "transparent",
            }}
            onClick={() => removePackage(selectedPackage)}
          >
            <FaRegTrashAlt className="text-danger" />
            Remove this Package
          </button>
          <Slider ref={sliderTabRef} {...packageSettings}>
            {Package?.map((PackageItems, index) =>
              PackageItems.package_items?.length > 0 ? (
                <div key={index}>
                  {PackageItems?.package_items.map((item, id) => (
                    <div className="Box" key={index}>
                      <div className="Left">
                        <div className="circle">{item.quantity}</div>
                        <div className="Detail">
                          <h4>{item.name}</h4>
                          <h5>{item.description}</h5>
                        </div>
                      </div>
                      <div
                        onClick={() => handleEditOpen({ ...item, id: id })}
                        className="Right"
                      >
                        <svg
                          width="17"
                          height="5"
                          viewBox="0 0 17 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.0488 2.94775C12.0488 2.41732 12.2595 1.90861 12.6346 1.53354C13.0097 1.15847 13.5184 0.947754 14.0488 0.947754C14.5793 0.947754 15.088 1.15847 15.463 1.53354C15.8381 1.90861 16.0488 2.41732 16.0488 2.94775C16.0488 3.47819 15.8381 3.98689 15.463 4.36197C15.088 4.73704 14.5793 4.94775 14.0488 4.94775C13.5184 4.94775 13.0097 4.73704 12.6346 4.36197C12.2595 3.98689 12.0488 3.47819 12.0488 2.94775ZM6.04883 2.94775C6.04883 2.41732 6.25954 1.90861 6.63461 1.53354C7.00969 1.15847 7.51839 0.947754 8.04883 0.947754C8.57926 0.947754 9.08797 1.15847 9.46304 1.53354C9.83811 1.90861 10.0488 2.41732 10.0488 2.94775C10.0488 3.47819 9.83811 3.98689 9.46304 4.36197C9.08797 4.73704 8.57926 4.94775 8.04883 4.94775C7.51839 4.94775 7.00969 4.73704 6.63461 4.36197C6.25954 3.98689 6.04883 3.47819 6.04883 2.94775ZM0.0488281 2.94775C0.0488281 2.41732 0.259542 1.90861 0.634614 1.53354C1.00969 1.15847 1.5184 0.947754 2.04883 0.947754C2.57926 0.947754 3.08797 1.15847 3.46304 1.53354C3.83811 1.90861 4.04883 2.41732 4.04883 2.94775C4.04883 3.47819 3.83811 3.98689 3.46304 4.36197C3.08797 4.73704 2.57926 4.94775 2.04883 4.94775C1.5184 4.94775 1.00969 4.73704 0.634614 4.36197C0.259542 3.98689 0.0488281 3.47819 0.0488281 2.94775Z"
                            fill="#151A42"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="Box">
                  <div className="Left">
                    <div className="Detail">
                      <h4>You have not added any item to this package</h4>
                    </div>
                  </div>
                </div>
              )
            )}
          </Slider>
          <div onClick={handleOpen} className="chip">
            Add Item
          </div>
        </div>
        <h4>SUMMARY</h4>
        <ul>
          <li>
            TOTAL QUANTITY
            <span>{Package[selectedPackage]?.package_items?.length}</span>
          </li>
          <li>
            TOTAL VALUE{" "}
            <span>
              {Package[selectedPackage]?.package_items?.length === 0
                ? 0
                : Package[selectedPackage]?.package_items?.reduce(
                    (sum, acc) => sum + parseFloat(acc?.value),
                    0
                  )}
            </span>
          </li>
          <li>
            INSURANCE
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 0 }} defaultChecked />}
              label=""
              onChange={handleInsurance}
              checked={insurance === "Yes"}
            />
          </li>
          <li>
            Package Description
            <div onClick={handleOverallOpen} className="btn change">
              change
            </div>
          </li>
        </ul>

        <button onClick={SubmitForm}>
          {isLoading ? (
            <span className="spinner-border spinner-grow-sm" />
          ) : (
            "CONTINUE"
          )}
        </button>
        <small>
          By continuing, I represent that the declaration above is a proper and
          accurate description of the contents of my package.
        </small>
        <NestedBtn handle={Previous} status={ReceiverStatus} name="previous" />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="Package_Modal_Wrapper">
              <div className="Box">
                <label htmlFor="des">CATEGORY</label>
                {/* <input
                  type="text"
                  placeholder="ex. Brown laptop bag made of cotton"
                  className={`${errors.name ? "error" : ""}`}
                  {...register("name", { required: true })}
                /> */}
                <input
                  type="text"
                  id="Name"
                  placeholder="Category"
                  autoComplete="false"
                  autoCorrect="false"
                  className={`${catError ? "error" : ""} Country`}
                  value={addCatQuery}
                  // className={`Country ${
                  //   countryError === true ? "error" : "noError"
                  // }`}
                  onChange={(e) => searchAddCategory(e.target.value)}
                />
                {/* {countryError && (
              <span className={`${countryError ? "errorText" : ""}`}>
                Country is required
              </span>
            )} */}
                <div
                  style={{ width: "86%" }}
                  className={`suggestions-field ${
                    showAddCatSuggestions ? "suggestions-field-visible" : null
                  }`}
                >
                  {addCatSuggestions.map((suggestions) => {
                    return (
                      <div
                        onClick={() => selectAddCategory(suggestions)}
                        key={suggestions}
                        className="single-suggestion"
                      >
                        <span style={{ fontSize: 14, color: "#74788d" }}>
                          {suggestions}
                        </span>
                      </div>
                    );
                  })}
                </div>
                {catError && (
                  <span className={`${catError ? "errorText" : ""}`}>
                    Category field is required
                  </span>
                )}
              </div>
              <p>Give the category of what you are shipping</p>
              <div className="Box">
                <label htmlFor="cat">DESCRIPTION</label>
                <textarea
                  placeholder="Enter the description of the item"
                  className={`${errors.description ? "error" : ""}`}
                  {...register("description", { required: true })}
                ></textarea>
                {errors.description && (
                  <span className={`${errors.description ? "errorText" : ""}`}>
                    Description field is required
                  </span>
                )}
              </div>
              <p>
                Give a brief description that best describes your item. This is
                required for effective customs clearance when you ship
                internationally.
              </p>
              <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">WEIGHT (KG)</label>
                  <input
                    type="tel"
                    placeholder="Enter Weight"
                    className={`${errors.weight ? "error" : ""}`}
                    {...register("weight", { required: true })}
                  />
                  {errors.weight && (
                    <span className={`${errors.weight ? "errorText" : ""}`}>
                      Weight field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">QUANTITY</label>
                  <input
                    type="tel"
                    placeholder="Enter quantity"
                    className={`${errors.quantity ? "error" : ""}`}
                    {...register("quantity", { required: true })}
                  />
                  {errors.quantity && (
                    <span className={`${errors.quantity ? "errorText" : ""}`}>
                      Quantity field is required
                    </span>
                  )}
                </div>
              </div>
              <p>
                Provide the value and quantity of the item you are trying to
                declare for shipping.
              </p>
              <div className="Top">
                <div className="Box">
                  <label htmlFor="cat">VALUE PER ITEM</label>
                  <input
                    type="tel"
                    placeholder="Enter value"
                    className={`${errors.value ? "error" : ""}`}
                    {...register("value", { required: true })}
                  />
                  {errors.value && (
                    <span className={`${errors.value ? "errorText" : ""}`}>
                      Value field is required
                    </span>
                  )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">CURRENCY</label>
                  <select
                    name="value_currency"
                    className={`${errors.value_currency ? "error" : ""}`}
                    {...register("value_currency", { required: true })}
                  >
                    <option value="">Choose Currency</option>
                    <option value="USD">USD</option>
                    <option value="NGN">NGN</option>
                  </select>
                  {errors.currency && (
                    <span className={`${errors.currency ? "errorText" : ""}`}>
                      Currency is required
                    </span>
                  )}
                </div>
              </div>
              {insurance === "Yes" && (
                <div className="Box">
                  <label htmlFor="cat">ITEM IMAGE</label>
                  <div className=" position-relative w-100 ImgContain">
                    <input
                      type="file"
                      accept="image/*"
                      className="ImgInput"
                      disabled={imgLoading}
                      onChange={handleInsuranceFile}
                    />
                    <div className="px-2 py-3">
                      <div className="d-flex justify-content-center">
                        {imgLoading ? (
                          <span className="spinner-border spinner-grow-sm" />
                        ) : (
                          <FiUploadCloud className="UploadIcon" />
                        )}
                      </div>
                      {itemImg ? (
                        <p>{itemImg?.name}</p>
                      ) : (
                        <p>Click to upload an image of your package item</p>
                      )}
                    </div>
                  </div>
                  <p>
                    NB. You are to provide an image of your item when you choose
                    to insure your package.
                  </p>
                </div>
              )}
              <button
                disabled={imgLoading}
                onClick={handleSubmit(addPackageItem)}
              >
                ADD ITEM
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Editopen}
        onClose={handleEditClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={Editopen}>
          <Box sx={style}>
            {EditPackage !== null && (
              <div className="Package_Modal_Wrapper">
                <div className="Box">
                  <label htmlFor="des">CATEGORY</label>
                  {/* <input
                    type="text"
                    value={EditPackage.name}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        name: e.target.value,
                      }))
                    }
                  /> */}
                  <input
                    type="text"
                    id="Name"
                    placeholder="Category"
                    autoComplete="false"
                    autoCorrect="false"
                    value={categoryQuery}
                    // className={`Country ${
                    //   countryError === true ? "error" : "noError"
                    // }`}
                    className={`Country`}
                    onChange={(e) => searchCategory(e.target.value)}
                  />
                  {/* {countryError && (
              <span className={`${countryError ? "errorText" : ""}`}>
                Country is required
              </span>
            )} */}
                  <div
                    style={{ width: "86%" }}
                    className={`suggestions-field ${
                      showCategoriesSuggestions
                        ? "suggestions-field-visible"
                        : null
                    }`}
                  >
                    {categorySuggestions.map((suggestions) => {
                      return (
                        <div
                          onClick={() => selectEditCategory(suggestions)}
                          key={suggestions}
                          className="single-suggestion"
                        >
                          <span style={{ fontSize: 14, color: "#74788d" }}>
                            {suggestions}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p>Give the category of what you are shipping</p>
                <div className="Box">
                  <label htmlFor="cat">DESCRIPTION</label>
                  <textarea
                    value={EditPackage.description}
                    onChange={(e) =>
                      setEditPackage((pre) => ({
                        ...pre,
                        description: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                <p>
                  Give a brief description that best describes your item. This
                  is required for effective customs clearance when you ship
                  internationally.
                </p>
                <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">WEIGHT (KG)</label>
                    <input
                      type="tel"
                      value={EditPackage.weight}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          weight: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">QUANTITY</label>
                    <input
                      type="tel"
                      value={EditPackage.quantity}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          quantity: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="Top">
                  <div className="Box">
                    <label htmlFor="cat">VALUE PER ITEM</label>
                    <input
                      type="tel"
                      value={EditPackage.value}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          value: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="Box">
                    <label htmlFor="cat">CURRENCY</label>
                    <select
                      name="value_currency"
                      value={EditPackage.value_currency}
                      onChange={(e) =>
                        setEditPackage((pre) => ({
                          ...pre,
                          value_currency: e.target.value,
                        }))
                      }
                    >
                      <option value="">Choose Currency</option>
                      <option value="USD"> USD</option>
                      <option value="NGN">NGN</option>
                    </select>
                  </div>
                </div>
                <p>
                  Provide the value and quantity of the item you are trying to
                  declare for shipping.
                </p>
                {insurance === "Yes" && (
                  <div className="Box">
                    <label htmlFor="cat">ITEM IMAGE</label>
                    <div className=" position-relative w-100 ImgContain">
                      <input
                        type="file"
                        accept="image/*"
                        className="ImgInput"
                        disabled={imgLoading}
                        onChange={handleInsuranceFile}
                      />
                      <div className="px-2 py-3">
                        <div className="d-flex justify-content-center">
                          {imgLoading ? (
                            <span className="spinner-border spinner-grow-sm" />
                          ) : (
                            <FiUploadCloud className="UploadIcon" />
                          )}
                        </div>
                        {itemImg || EditPackage.image_url ? (
                          <p>
                            {itemImg ? itemImg?.name : EditPackage.image_name}
                          </p>
                        ) : (
                          <p>Click to upload an image of your package item</p>
                        )}
                      </div>
                    </div>
                    <p>
                      NB. You are to provide an image of your item when you
                      choose to insure your package.
                    </p>
                  </div>
                )}
                <div className="d-flex gap-4">
                  <button
                    className="btnTab"
                    onClick={() => removeItem(EditPackage?.id, selectedPackage)}
                  >
                    REMOVE ITEM
                  </button>
                  <button onClick={SubmitUpdate} disabled={imgLoading}>
                    UPDATE ITEM
                  </button>
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={OveralModal}
        onClose={() => setOveralModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={OveralModal}>
          <Box sx={style}>
            <div className="Package_Modal_Wrapper">
              <div className="Triple_Box">
                <div className="Box">
                  <label htmlFor="cat">Length(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_length}
                    onChange={handleSummaryInput}
                    name="package_length"
                    placeholder="Enter Length"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_length === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_length === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Length is required
                      </span>
                    )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">Width(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_width}
                    onChange={handleSummaryInput}
                    name="package_width"
                    placeholder="Enter Width"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_width === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_width === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Width is required
                      </span>
                    )}
                </div>
                <div className="Box">
                  <label htmlFor="cat">Height(CM)</label>
                  <input
                    value={Package[selectedPackage]?.package_height}
                    onChange={handleSummaryInput}
                    name="package_height"
                    placeholder="Enter Height"
                    className={`${
                      packageIsEmpty &&
                      Package[selectedPackage]?.package_height === ""
                        ? "error"
                        : ""
                    }`}
                  />
                  {packageIsEmpty &&
                    Package[selectedPackage]?.package_height === "" && (
                      <span className={`${isEmpty ? "errorText" : ""}`}>
                        Height is required
                      </span>
                    )}
                </div>
              </div>
              <div className="Box">
                <label htmlFor="cat">DESCRIPTION</label>
                <textarea
                  value={Package[selectedPackage]?.description}
                  onChange={handleSummaryInput}
                  name="description"
                  placeholder="Enter overall description of the item"
                  className={`${isEmpty ? "error" : ""}`}
                ></textarea>
                {isEmpty && (
                  <span className={`${isEmpty ? "errorText" : ""}`}>
                    Overall Description is required
                  </span>
                )}
              </div>
              <button onClick={OveralSubmit}>ADD PACKAGE DETAILS</button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PackageDetail;
