import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import NavigationButton from "../../../components/NavigateBtn";
import Moment from "react-moment"
import { NumericFormat } from 'react-number-format';

const AllShipmentsTable = ({
    startDate,
    endDate,
    filterType,
    setSelectedShipment
}) => {

    const { ShipmentHistory } = useSelector((state) => state.Auth)

    const [CurrentPage, setCurrentPage] = useState(1)
    const [PostPerPage, setPostPerPage] = useState(5)
    const LastPost = CurrentPage * PostPerPage;
    const FirstPost = LastPost - PostPerPage;
    const Output = ShipmentHistory.filter(item => item.status?.includes(filterType))?.slice(FirstPost, LastPost);

    // Function to filter items based on start and end dates
    function filterItemsByDate(start, end, walletHistory) {
        // Check if start or end date is null or an empty string
        if (!start || start.trim() === "" || !end || end.trim() === "") {
            return walletHistory; // Return all items if either date is null or empty
        }

        // Parse input dates as JavaScript Date objects
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Filter items based on the date range
        const filteredItems = walletHistory.filter((item) => {
            const itemDate = new Date(item.date_time);

            // Check if item's date range overlaps with the input date range
            return itemDate <= endDate && itemDate >= startDate;
        });

        return filteredItems;
    };

    let shipmentHistory = filterItemsByDate(startDate, endDate, Output)

    const render = (res) => {
        switch (res) {
            case "Pending Pickup":
                return "Pending"
                break;
            case "Cancelled":
                return "Cancel"
                break;
            case "Rejected":
                return "Rejected"
                break;
            case "Delivered":
                return "Delivered"
                break;
            case "Pending Approval":
                return "Pending Approval"
                break;

            default:
                return "Success"
                break;
        }
    }

    const renderColor = (res) => {
        switch (res) {
            case "Pending Pickup":
                return "pending"
                break;
            case "Cancelled":
                return "cancel"
                break;
            case "Rejected":
                return "cancel"
                break;
            case "Delivered":
                return "success"
                break;
            case "Pending Approval":
                return "pending"
                break;

            default:
                return "success"
                break;
        }
    }



    return (
        <Fragment>
            <div className="AllShipment_Table_Wrapper">
                <table>
                    <thead>
                        <th>DROP OFF ADDRESS</th>
                        <th>RECEIVER NAME</th>
                        <th>SHIPMENT NUMBER</th>
                        <th>AMOUNT</th>
                        <th>CREATION DATE</th>
                        <th>STATUS</th>
                    </thead>
                    <tbody>
                        {
                            shipmentHistory.map(item => (
                                <tr onClick={()=>setSelectedShipment(item)} >

                                    <td><div className="Box">
                                        <h5>{item.sender_name}</h5>
                                        <p>{item.sender_phone_no}</p>
                                        <p>{`${item?.sender_address_1}`} {item?.sender_address_2 && `, ${item?.sender_address_2}`} </p>
                                    </div></td>

                                    <td><div className="Box">
                                        <h5>{item.receiver_name}</h5>
                                        <p>{item.receiver_phone_no}</p>
                                        <p>{`${item?.receiver_address_1}`} {item?.receiver_address_2 !== "" ? `, ${item?.receiver_address_2}` : ""} </p>
                                    </div></td>

                                    <td>
                                        <div className="Box">
                                            <h5>{item?.shipment_no}</h5>
                                        </div>
                                    </td>

                                    <td><div className="Box">
                                        <h5><NumericFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></h5>
                                    </div></td>

                                    <td><div className="Box">
                                        <h5><Moment format="MMM DD, YY">{item.date_time}</Moment></h5>
                                        <p><Moment format="h:mm a">{item.date_time}</Moment></p>
                                    </div></td>

                                    <td>
                                        <div className="Box">
                                            <div className={`Btn ${renderColor(item.status)}`}>{render(item.status)}</div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className="mobileCards" >
                    {
                        shipmentHistory.map(item => (
                            <div className="ShipCard" key={item.id} onClick={()=>setSelectedShipment(item)} >

                                <div><div className="Box">
                                    <h5>Dropoff Info</h5>
                                    <p>{item.sender_name}</p>
                                    <p>{item.sender_phone_no}</p>
                                    <p>{item.pick_up_location}</p>
                                </div></div>

                                <div><div className="Box">
                                    <h5>Pickup Info</h5>
                                    <p>{item.recipient_name}</p>
                                    <p>{item.recipient_phone_no}</p>
                                    <p>{item.drop_off_location}</p>
                                </div></div>

                                <div className="BoxContain">
                                    <div>
                                        <h5>Shipment Number</h5>
                                        <p>{item?.shipment_no} </p>
                                    </div>

                                    <div><div className="Box">
                                        <h5>Amount</h5>
                                        <p><NumericFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></p>
                                    </div></div>
                                </div>

                                <div className="BoxContain">
                                    <div><div className="Box">
                                        <h5>Created At</h5>
                                        <p><Moment format="MMM DD, YY">{item.date_time}</Moment>, <Moment format="h:mm a">{item.date_time}</Moment></p>
                                    </div></div>

                                    <div>
                                        <div className="Box">
                                            <h5 style={{textAlign:"right"}} >Status</h5>
                                            <div className={`Btn ${renderColor(item.status)}`}>{render(item.status)}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
            <NavigationButton
                CurrentPage={CurrentPage}
                setCurrentPage={setCurrentPage}
                output={shipmentHistory}
            />
        </Fragment>
    );
}

export default AllShipmentsTable;