import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import countries from "../../../Assets/Files/countries.json";
import { useSelector } from "react-redux";
import { getRatesX, GetCountries } from "../../../Library/URLs";
import axios from "axios";
import { generateTransactionID } from "../../../Library/Utilities";
import RatesModal from "./RatesModal";
import { toast } from "react-toastify";
import allCountries from "../../../Data/countries.json";

const International = ({ rateType }) => {
  const { User } = useSelector((state) => state.Auth);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carriers, setCarriers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [SelectedCountry, setSelectedCountry] = useState("Nigeria");
  const [receiverSelectedCountry, setReceiverSelectedCountry] =
    useState("Nigeria");

  const getCountries = () => {
    const res = allCountries?.map((country) => ({
      ...country,
      name: country?.country,
      code: country?.iso_code,
    }));
    setCountries(res);
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(rateType==="Import") {
      setRecieverCountry("Nigeria")
    }
  }, [rateType])

  const [senderCountry, setSenderCountry] = useState("");
  const [senderCountryError, setSenderCountryError] = useState();
  // const {Sender,SenderCountry,SenderState} = useSelector((state)=>state.International)
  const [receiverCountry, setRecieverCountry] = useState("");
  const [receiverCountryError, setReceiverCountryError] = useState();

  const [showSenderCountriesSuggestions, setShowSenderCountriesSuggestions] =
    useState(false);
  const [countriesSuggestions, setCountriesSuggestions] = useState([]);

  const [
    showReceiverCountriesSuggestions,
    setShowReceiverCountriesSuggestions,
  ] = useState(false);

  const [weight, setWeight] = useState("");
  const [weightError, setWeightError] = useState();

  const getRates = async () => {
    const data = {
      user_id: User.userID,
      user_ses_id: User.sessionID,
      company_id: User.companyID,
      transaction_id: generateTransactionID(15),
      shipment_type: rateType === "Import" ? "Import" : "International",
      from: SelectedCountry,
      to: receiverSelectedCountry,
      weight: weight,
    };
    setIsLoading(true);

    await axios.post(getRatesX, data).then((response) => {
      console.log("shipment history res", response.data);
      setIsLoading(false);
      if (response.data.success === false) {
        toast.error(response.data.message);
      }
      if (response.data.success === true) {
        const res = response.data.data;
        setCarriers(res?.carriers);
        setOpenModal(true);
      }
    });
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (senderCountry === "") {
      setSenderCountryError(true);
    }
    if (receiverCountry === "") {
      setReceiverCountryError(true);
    }
    if (weight === "") {
      setWeightError(true);
    }
    if (senderCountry !== "" && receiverCountry !== "" && weight !== "") {
      getRates();
    }
  };

  const selectCountry = (location) => {
    setSenderCountry(location);
    setSelectedCountry(location);
    setSenderCountryError(false);
    setShowSenderCountriesSuggestions(false);
  };

  const selectReceiverCountry = (location) => {
    setRecieverCountry(location);
    setReceiverSelectedCountry(location);
    setReceiverCountryError(false);
    setShowReceiverCountriesSuggestions(false);
  };

  const searchCountry = (query, name) => {
    // dispatch(InternationalActions.GetCost(false))
    if (name === "from") {
      setSenderCountry(query);

      if (query === "") {
        setShowSenderCountriesSuggestions(false);
        return;
      }
    } else {
      setRecieverCountry(query);

      if (query === "") {
        setShowReceiverCountriesSuggestions(false);
        return;
      }
    }

    const filteredCountries = [];
    countries.forEach((country) => {
      if (country["name"].toLowerCase().includes(query.toLowerCase())) {
        filteredCountries.push(country["name"]);
      }
    });

    setCountriesSuggestions(filteredCountries);
    if (filteredCountries.length > 0) {
      if (name === "from") {
        setShowSenderCountriesSuggestions(true);
      } else {
        setShowReceiverCountriesSuggestions(true);
      }
    } else {
      if (name === "from") {
        setShowSenderCountriesSuggestions(false);
      } else {
        setShowReceiverCountriesSuggestions(false);
      }
    }
  };
  return (
    <div className="Rate_form">
      <RatesModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        carriers={carriers}
      />
      <h3>Check Shipping rates across countries</h3>
      <form>
        <div className="Form_Control">
          <label htmlFor="country">FROM</label>
          <input
            type="text"
            id="country"
            placeholder="Country"
            autoComplete="false"
            autoCorrect="false"
            value={senderCountry}
            className={`Country ${
              senderCountryError === true ? "error" : "noError"
            }`}
            onChange={(e) => searchCountry(e.target.value, "from")}
          />
          {senderCountryError && (
            <span className={`${senderCountryError ? "errorText" : ""}`}>
              Sender country is required
            </span>
          )}
          <div
            style={{ width: "86%" }}
            className={`suggestions-field ${
              showSenderCountriesSuggestions
                ? "suggestions-field-visible"
                : null
            }`}
          >
            {rateType === "Export"
              ? countriesSuggestions.map((suggestions) => {
                  return (
                    <div
                      onClick={(e) => selectCountry(suggestions, "Pickup")}
                      key={suggestions}
                      className="single-suggestion"
                    >
                      <span style={{ fontSize: 14, color: "#74788d" }}>
                        {suggestions}
                      </span>
                    </div>
                  );
                })
              : countriesSuggestions
                  .filter(
                    (suggestions) =>
                      suggestions === "United States" ||
                      suggestions === "United Kingdom" ||
                      suggestions === "China"
                  )
                  ?.map((suggestions) => {
                    return (
                      <div
                        onClick={(e) => selectCountry(suggestions, "Pickup")}
                        key={suggestions}
                        className="single-suggestion"
                      >
                        <span style={{ fontSize: 14, color: "#74788d" }}>
                          {suggestions}
                        </span>
                      </div>
                    );
                  })}
          </div>
        </div>
        <div className="Form_Control">
          <label htmlFor="country">TO</label>
          <input
            type="text"
            id="country"
            placeholder="Country"
            autoComplete="false"
            autoCorrect="false"
            disabled={rateType==="Import"}
            value={receiverCountry}
            className={`Country ${
              receiverCountryError === true ? "error" : "noError"
            }`}
            onChange={(e) => searchCountry(e.target.value, "receive")}
          />
          {receiverCountryError && (
            <span className={`${receiverCountryError ? "errorText" : ""}`}>
              Receiver country is required
            </span>
          )}
          <div
            style={{ width: "86%" }}
            className={`suggestions-field ${
              showReceiverCountriesSuggestions
                ? "suggestions-field-visible"
                : null
            }`}
          >
            {rateType === "Export"
              ? countriesSuggestions.map((suggestions) => {
                  return (
                    <div
                      onClick={(e) =>
                        selectReceiverCountry(suggestions, "Pickup")
                      }
                      key={suggestions}
                      className="single-suggestion"
                    >
                      <span style={{ fontSize: 14, color: "#74788d" }}>
                        {suggestions}
                      </span>
                    </div>
                  );
                })
              : countriesSuggestions
                  .filter((suggestions) => suggestions === "Nigeria")
                  .map((suggestions) => {
                    return (
                      <div
                        onClick={(e) =>
                          selectReceiverCountry(suggestions, "Pickup")
                        }
                        key={suggestions}
                        className="single-suggestion"
                      >
                        <span style={{ fontSize: 14, color: "#74788d" }}>
                          {suggestions}
                        </span>
                      </div>
                    );
                  })}
          </div>
        </div>
        <div className="Form_Control">
          <label htmlFor="country">WEIGHT (KG)</label>
          <input
            type="tel"
            id="weight"
            placeholder="Weight"
            autoComplete="false"
            autoCorrect="false"
            value={weight}
            className={`Country ${weightError === true ? "error" : "noError"}`}
            onChange={(e) => setWeight(e.target.value)}
          />
          {weightError && (
            <span className={`${weightError ? "errorText" : ""}`}>
              Weight is required
            </span>
          )}
        </div>
        <div onClick={handleSubmit(onSubmit)} className="FormBtn">
          {isLoading ? (
            <span className="spinner-border spinner-grow-sm" />
          ) : (
            "CONTINUE"
          )}
        </div>
      </form>
    </div>
  );
};

export default International;
